import { gql } from '@apollo/client';

import { ARTICLE_RICH_TEXT_FRAGMENT } from '../articleRichText';
import { MEDIA_FRAGMENT } from '../media';

export const ARTICLE_CARD_FRAGMENT = gql`
  fragment ArticleCard on Article {
    id
    eyebrow
    title
    slug
    locale
    externalNewsUrl
    description
    type
    publisherLogo {
      ...Media
    }
    externalPerson {
      image {
        ...Media
      }
      name
      jobTitle
      organization
    }
    articleBody: body {
      ...ArticleRichText
    }
    articlePerson: person {
      image {
        ...Media
      }
      name
      jobTitle

      areaOfWork {
        ... on Department {
          id
          name
        }

        ... on GrantMakingArea {
          id
          name
        }
      }
    }
    articleDate: date
    hideArticleDate: hideDate
    articleHeroImage: heroImage {
      ...Media
    }
  }
  ${MEDIA_FRAGMENT}
  ${ARTICLE_RICH_TEXT_FRAGMENT}
`;
