import { gql } from '@apollo/client';

import { MEDIA_FRAGMENT } from './media';

export const QUOTE_FRAGMENT = gql`
  fragment Quote on Quote {
    audio {
      ...Media
    }
    quoteImage {
      ...Media
    }
    quoteLink: link
    linkText
    attribution
    text
    isTwitterImage
    twitterUsername
  }
  ${MEDIA_FRAGMENT}
`;
