import { gql } from '@apollo/client';

import { MEDIA_FRAGMENT } from './media';

export const QUICK_LINK_FRAGMENT = gql`
  fragment QuickLink on QuickLink {
    asset {
      ...Media
    }
    externalLink
    internalLink {
      id
      contentType
      slug
      subType
    }
    label
  }
  ${MEDIA_FRAGMENT}
`;
