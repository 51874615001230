import { gql } from '@apollo/client';

export const MEDIA_FRAGMENT = gql`
  fragment Media on Media {
    src
    title
    description
    mediaHeight: height
    mediaWidth: width
  }
`;
