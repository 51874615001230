import { gql } from '@apollo/client';

import { MEDIA_FRAGMENT } from '../media';

export const INLINE_IMAGES_FRAGMENT = gql`
  fragment InlineImages on InlineImages {
    images {
      ...Media
    }
  }
  ${MEDIA_FRAGMENT}
`;
