import { gql } from '@apollo/client';

import { QUOTE_FRAGMENT } from './quote';

export const INITIATIVE_QUOTE_FRAGMENT = gql`
  fragment InitiativeQuote on InitiativeQuoteCallOut {
    initiativeCtaText
    initiativeHeading
    initiativeText
    initiativeTitle
    quote {
      ...Quote
    }
    searchQuery
  }
  ${QUOTE_FRAGMENT}
`;
