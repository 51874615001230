import { gql } from '@apollo/client';

import { MEDIA_FRAGMENT } from './media';

export const SPOTLIGHT_FRAGMENT = gql`
  fragment Spotlight on Spotlight {
    ... on SpotlightShow {
      headline
      subHeadline
      images {
        ...Media
      }
    }

    ... on SpotlightVideo {
      headline
      subHeadline
      video {
        ...Media
      }
      videoCaptions {
        ...Media
      }
      videoUrl
      previewVideo {
        ...Media
      }
      previewVideoUrl
      ctaLabel
      previewStaticImage {
        ...Media
      }
    }
  }
  ${MEDIA_FRAGMENT}
`;
