import { gql } from '@apollo/client';

import { MEDIA_FRAGMENT } from './media';

export const NATIVE_VIDEO_FRAGMENT = gql`
  fragment NativeVideo on NativeVideo {
    video {
      ...Media
    }
    closedCaptions {
      ...Media
    }
    caption
    preview {
      ...Media
    }
  }
  ${MEDIA_FRAGMENT}
`;

export const YOUTUBE_VIDEO_FRAGMENT = gql`
  fragment YouTubeVideo on YouTubeVideo {
    url
    caption
    preview {
      ...Media
    }
  }
`;
